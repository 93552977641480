import axios from "axios";
const baseURL = import.meta.env.VITE_API_URI;
console.log(baseURL);
class ApiClient {
  constructor() {
    this.setClient();
  }

  setClient() {
    this.client = axios.create({
      baseURL: baseURL,
      timeout: 1000000,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    });

    // Перехватчик ответов для обработки ошибок, если необходимо
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        let errors = error?.response?.data?.errors;
        if (!errors) {
          const responseCode = error?.response?.status;

          if (responseCode) {
            if (responseCode === 400) {
              errors = ["Возникла ошибка при выполнении запроса на сервер."];
            }

            if (responseCode === 429) {
              errors = [
                "Слишком много попыток, попробуйте через несколько минут.",
              ];
            } else if (responseCode === 401 || responseCode === 403) {
              localStorage.removeItem("token");
            }
          } else {
            errors = ["Возникла непредвиденая ошибка."];
          }
        }

        return Promise.reject(errors);
      },
    );
  }

  async recreate() {
    this.setClient();
  }

  async get(url, params = {}) {
    return await this.client.get(url, { params });
  }

  async post(url, data = {}, config = {}) {
    return await this.client.post(url, data, config);
  }

  async put(url, data = {}, config = {}) {
    return await this.client.put(url, data);
  }

  async patch(url, data = {}, config = {}) {
    return await this.client.patch(url, data, config);
  }

  async delete(url) {
    return await this.client.delete(url);
  }
}

const api = new ApiClient();

export default api;
