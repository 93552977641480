<template>
  <svg
    :height="height"
    width="133"
    viewBox="0 0 133 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    Snegbet
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
    },
  },
};
</script>
