<template>
  <div class="app-navbar-actions" v-if="settings" style="padding: 25px 30px">
    <VaSwitch
      :true-value="1"
      v-if="settings.tech_work"
      :label="settings.tech_work.label"
      v-model="settings.tech_work.value"
    />

    <VaButton
      @click="goLogout"
      color="danger"
      class="ml-10"
      title="Выход"
      icon="mso-logout"
    />
  </div>
</template>

<script>
import { getSettings, saveSettings } from "../../../talentum/services/settings";

export default {
  data() {
    return {
      isInitialized: false,
      settings: null,
    };
  },
  watch: {
    "settings.tech_work.value": function () {
      if (this.isInitialized) {
        this.save();
      }
    },
  },
  methods: {
    goLogout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
      window.location.reload();
    },
    save() {
      saveSettings({
        tech_work: this.settings.tech_work.value ? 1 : 0,
      })
        .then(() => {
          this.$vaToast.init({
            color: "success",
            message: "Успешно обновлено.",
          });
        })
        .catch(() => {
          this.$vaToast.init({ color: "danger", message: "Unknown error." });
        })
        .finally(() => {
          this.getSettings();
        });
    },
    getSettings() {
      getSettings()
        .then((response) => {
          this.settings = response.data;
        })
        .finally(() => {
          this.isInitialized = true;
        });
    },
  },
  mounted() {
    this.getSettings();
  },
};
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
