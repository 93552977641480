import api from "../api";

export function getSettings(data) {
  return api.get("config", data);
}

export function saveSettings(data) {
  return api.post("config", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
